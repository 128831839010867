<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/system-admin/organisations">
          <material-stats-card
            color="#156a59"
            icon="mdi-domain"
            title="Organisation Administration"
            v-model="value"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>

      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/system-admin/line-items">
          <material-stats-card
            color="#156a59"
            icon="mdi-receipt"
            title="Line Item Administration"
            value="0"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      
       <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/system-admin/line-items-update">
          <material-stats-card
            color="#156a59"
            icon="mdi-receipt"
            title="Line Item update"
            value="0"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      
    </v-row>

  </v-container>
</template>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
</style>
<script>
export default {
  data: () => ({
    value: 0
  }),
  mounted() {
    this.init();
  },
  computed: {
    
  },
  methods: {
    async init() {
            
      const orgRef = this.$db.collection("organisations");
      const snapshot = await orgRef.get();
      this.value=snapshot.size;
      
    }
  }
};
</script>
